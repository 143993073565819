import React, { useEffect, useState } from "react";
import "./main.css";
import "./my.css";
import Nav from "./components/NavBar";
import { Route, Routes, useNavigate } from "react-router-dom";
import axios from "axios";
import NewOrder from "./components/NewOrder";
import MyOrders from "./components/Sidebar-MyOrders";
import OrderGenerals from "./components/Order-Generals";
import Mediaplan from "./components/Order-Mediaplan";
import FinancialInsight from "./components/Order-FI";
import Process from "./components/Order-Process";
import Communication from "./components/Order-Communication";
import UnsortedCommunication from "./components/Sidebar-UnsortedCommunication";
//import Login from "./components/Login";
//import { Amplify, Auth } from "aws-amplify";
import UsersManagement from "./components/UsersManagement";
import OrderMenu from "./components/Order-Menu";
import RecievedInvoices from "./components/Order-RI";
import IssuedInvoices from "./components/Order-II";
import ClientsManagement from "./components/ClientsManagement";
import LogOut from "./components/LogOut";
import Home from "./components/Home";
import UnassignedInvoicesBez from "./components/Sidebar-UnassignedInv-Bez";
import Downloads from "./components/Downloads";
import Sidebar from "./components/Sidebar";
import UnassignedInvoicesSpatne from "./components/Sidebar-UnassignedInv-Spatne";
import DepositInvoices from "./components/Sidebar-DepositInvoices";
import PendingInvoices from "./components/Order-Pending_invoices";
import NewInvoice from "./components/NewInvoice";
import SafeKurzy from "./components/SafeKurzy";
export default function App() {
  const [user, setUser] = useState(0);
  const [userEmail, setUserEmail] = useState(0);
  const [userName, setUserName] = useState("");
  const [userDobropis, setUserDobropis] = useState(0);
  const [userRole, setUserRole] = useState(0);

  const [zakazkaFaktury, setZakazkaFaktury] = useState([]);
  const [zakazka, setZakazka] = useState([]);
  const [zakazkaF, setZakazkaF] = useState([]);
  const [mails, setMails] = useState([]);
  const [unassignedInv, setUnassignedInv] = useState([]);
  const [depositInvoices, setDepositInvoices] = useState([]);

  const [vybranaZakazka, setVybranaZakazka] = useState(0);
  const [vybranyMediatyp, setVybranyMediatyp] = useState("");
  const [enter, setEnter] = useState("");

  const [invoiceInsight, setInvoiceInsight] = useState("");
  const [selectedTab, setSelectedTab] = useState(0);

  const [isLoading, setLoading] = useState(true);
  const [rolesUpdated, setRolesUpdated] = useState(false); //pridane kvoli re run useffect po mass editaci
  const [emailDeleted, setEmailDeleted] = useState(false); //re run useffect sidebar
  const [emailRedirect, setEmailRedirect] = useState(false); //re run useffect in infobar after redirect z url emailu

  const [historyFilter, setHistoryFilter] = useState("2023-12-31"); //filter v myorders pre zakazky beziace v tomto roku

  const navigate = useNavigate();

  useEffect(() => {
    var tokenid = new URLSearchParams(window.location.hash).get("#id_token");

    if (sessionStorage.getItem("zs_mind2_url") == undefined) {
      sessionStorage.setItem("zs_mind2_url", window.location.pathname);
      sessionStorage.setItem("zs_mind2_url_params", window.location.search);
      sessionStorage.setItem("zs_mind2_url_hash", window.location.hash);
    }

    if ((tokenid !== "") & (tokenid !== null)) {
      localStorage.setItem("token", tokenid);
    }

    axios.get("/user").then(
      (res) => {
        setUser(res.data[0]["ROLE"]);
        setUserRole(res.data[0]["ROLE_LABEL"]);
        setUserEmail(res.data[0]["EMAIL"]);
        setUserName(res.data[0]["FULL_NAME"]);
        setUserDobropis(res.data[0]["DOBROPIS"]);

        console.log(res.data);
        console.log(user);

        if (sessionStorage.getItem("zs_mind2_url") == "/downloads") {
          // window.location.replace("http://localhost:3000/NewOrder");
          navigate("/downloads");
          sessionStorage.removeItem("zs_mind2_url");
        }
        //preklik uzivatela z emailu po zalozeni novej zakazky
        if (sessionStorage.getItem("zs_mind2_url_hash") == "#redirected") {
          navigate(
            sessionStorage.getItem("zs_mind2_url") +
              sessionStorage.getItem("zs_mind2_url_hash")
          );
          sessionStorage.removeItem("zs_mind2_url");
          sessionStorage.removeItem("zs_mind2_url_hash");
        }
      },
      (err) => {
        // setUser(false);
        console.log(err);
        const url =
          "https://mind2test.auth.eu-central-1.amazoncognito.com/login?client_id=5l88s06v0p2tv35l5077emg8ct&response_type=token&scope=email+openid&redirect_uri=https://zs.mind2.agency/";
        //"https://mind2purchases.auth.eu-central-1.amazoncognito.com/login?client_id=23utr2f02qkht4qu3hje08j9i5&response_type=token&scope=email+openid&redirect_uri=https://main.dtz8312xtaimm.amplifyapp.com/";
        //"https://mind2purchases.auth.eu-central-1.amazoncognito.com/login?client_id=23utr2f02qkht4qu3hje08j9i5&response_type=token&scope=email+openid&redirect_uri=http://localhost:3000/";

        window.location.replace(url);
      }
    );
  }, []);

  if (user) {
    return (
      <div className="App">
        <div class="page-title">
          <div class="container">
            <h1 class="page-title__name">Systém pro nákup médií</h1>
          </div>
        </div>
        <Nav
          user={user}
          setUser={setUser}
          userEmail={userEmail}
          userName={userName}
          userDobropis={userDobropis}
          userRole={userRole}
        />
        <Routes>
          <Route path="/downloads" element={<Downloads />} />
        </Routes>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="/Prehled"
            element={
              <Sidebar
                zakazka={zakazka}
                setZakazka={setZakazka}
                zakazkaFaktury={zakazkaFaktury}
                setZakazkaFaktury={setZakazkaFaktury}
                setVybranaZakazka={setVybranaZakazka}
                setVybranyMediatyp={setVybranyMediatyp}
                user={user}
                setUser={setUser}
                mails={mails}
                setMails={setMails}
                setEnter={setEnter}
                setSelectedTab={setSelectedTab}
                unassignedInv={unassignedInv}
                setUnassignedInv={setUnassignedInv}
                setDepositInvoices={setDepositInvoices}
                depositInvoices={depositInvoices}
                setLoading={setLoading}
                isLoading={isLoading}
                setZakazkaF={setZakazkaF}
                historyFilter={historyFilter}
                rolesUpdated={rolesUpdated}
                emailDeleted={emailDeleted}
              />
            }
          >
            <Route
              path="/Prehled/MyOrders"
              element={
                <MyOrders
                  zakazka={zakazka}
                  setZakazka={setZakazka}
                  zakazkaFaktury={zakazkaFaktury}
                  setZakazkaFaktury={setZakazkaFaktury}
                  setVybranaZakazka={setVybranaZakazka}
                  setVybranyMediatyp={setVybranyMediatyp}
                  user={user}
                  setUser={setUser}
                  setEnter={setEnter}
                  setSelectedTab={setSelectedTab}
                  isLoading={isLoading}
                  setZakazkaF={setZakazkaF}
                  zakazkaF={zakazkaF}
                  setRolesUpdated={setRolesUpdated}
                  rolesUpdated={rolesUpdated}
                  historyFilter={historyFilter}
                  setHistoryFilter={setHistoryFilter}
                />
              }
            />
            <Route
              path="/Prehled/unsortedcommunication"
              element={
                <UnsortedCommunication
                  mails={mails}
                  setMails={setMails}
                  user={user}
                  zakazka={zakazka}
                  emailDeleted={emailDeleted}
                  setEmailDeleted={setEmailDeleted}
                />
              }
            />
            <Route
              path="/Prehled/unassignedinvoicesbez"
              element={
                <UnassignedInvoicesBez
                  unassignedInv={unassignedInv}
                  setUnassignedInv={setUnassignedInv}
                  isLoading={isLoading}
                />
              }
            />
            <Route
              path="/Prehled/unassignedinvoicesspatne"
              element={
                <UnassignedInvoicesSpatne
                  unassignedInv={unassignedInv}
                  setUnassignedInv={setUnassignedInv}
                  isLoading={isLoading}
                />
              }
            />

            <Route
              path="/Prehled/depositinvoices"
              element={
                <DepositInvoices
                  depositInvoices={depositInvoices}
                  isLoading={isLoading}
                />
              }
            />
          </Route>
          <Route path="/NewOrder" element={<NewOrder />} />
          <Route path="/UsersManagement" element={<UsersManagement />} />
          <Route path="/ClientsManagement" element={<ClientsManagement />} />
          <Route path="/NewInvoice" element={<NewInvoice />} />
          <Route path="/SafeKurzy" element={<SafeKurzy user={user} />} />
          <Route path="/LogOut" element={<LogOut />} />

          <Route
            path="/OrderGenerals/:zakazka/:mediatyp"
            element={
              <OrderMenu
                zakazka={zakazka}
                vybranaZakazka={vybranaZakazka}
                setEnter={setEnter}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                emailRedirect={emailRedirect}
              />
            }
          >
            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/"
              element={
                <OrderGenerals
                  user={user}
                  zakazka={zakazka}
                  vybranaZakazka={vybranaZakazka}
                  enter={enter}
                  setEmailRedirect={setEmailRedirect}
                  emailRedirect={emailRedirect}
                />
              }
            />

            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/Communication"
              element={
                <Communication
                  vybranaZakazka={vybranaZakazka}
                  vybranyMediatyp={vybranyMediatyp}
                  user={user}
                />
              }
            />
            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/Mediaplan"
              element={
                <Mediaplan
                  vybranaZakazka={vybranaZakazka}
                  vybranyMediatyp={vybranyMediatyp}
                  invoiceInsight={invoiceInsight}
                  setInvoiceInsight={setInvoiceInsight}
                  enter={enter}
                  user={user}
                />
              }
            />
            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/Process"
              element={<Process vybranaZakazka={vybranaZakazka} user={user} />}
            />
            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/FinancialInsight"
              element={
                <FinancialInsight
                  zakazka={zakazka}
                  vybranaZakazka={vybranaZakazka}
                  vybranyMediatyp={vybranyMediatyp}
                  invoiceInsight={invoiceInsight}
                  setInvoiceInsight={setInvoiceInsight}
                  setEnter={setEnter}
                  setSelectedTab={setSelectedTab}
                  userName={userName}
                  user={user}
                />
              }
            />
            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/ReceivedInvoices"
              element={
                <RecievedInvoices
                  zakazka={zakazka}
                  vybranaZakazka={vybranaZakazka}
                  vybranyMediatyp={vybranyMediatyp}
                  invoiceInsight={invoiceInsight}
                  setInvoiceInsight={setInvoiceInsight}
                  setEnter={setEnter}
                  setSelectedTab={setSelectedTab}
                  user={user}
                />
              }
            />
            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/IssuedInvoices"
              element={
                <IssuedInvoices
                  zakazka={zakazka}
                  vybranaZakazka={vybranaZakazka}
                  vybranyMediatyp={vybranyMediatyp}
                  invoiceInsight={invoiceInsight}
                  setInvoiceInsight={setInvoiceInsight}
                  setEnter={setEnter}
                  setSelectedTab={setSelectedTab}
                  user={user}
                />
              }
            />
            <Route
              path="/OrderGenerals/:zakazka/:mediatyp/PendingInvoices"
              element={
                <PendingInvoices
                  zakazka={zakazka}
                  vybranaZakazka={vybranaZakazka}
                  vybranyMediatyp={vybranyMediatyp}
                  setEnter={setEnter}
                  setSelectedTab={setSelectedTab}
                  user={user}
                />
              }
            />
          </Route>
        </Routes>
      </div>
    );
  }
  return (
    <div className="App">
      <div class="loader">
        <div class="loader__in"></div>
      </div>
    </div>
  );
}
//export default App;
/*          <Route path="/login" component={Login} />
          <Route path="/register" component={Signup} />*/
