import React, { useEffect, useState } from "react";
import {
  CheckValidPaste,
  checkValidInput,
  replaceDot,
  numberWithSpaces,
  replaceComma,
} from "../helpers";
import Validation from "./Validation";
import axios from "axios";
import ModalRunning from "./modal_running";
export default function ModalInvoicesEvidenceMass(props) {
  const [isRunning, setIsRunning] = useState(false);
  const [validace, setValidace] = useState("");

  const Drawing = (id, val) => {
    const values = [...props.financialInsightF];
    var item = values.find((o) => o.ID == id);

    item["DRAW"] = val == "" ? null : val;

    props.setFinancialInsightF(values);
  };

  const EvidateInvoice = () => {
    for (let i = 0; i < props.financialInsightF.length; i++) {
      if (props.financialInsightF[i].DRAW != null) {
        props.financialInsightF[i].DRAW = parseFloat(
          props.financialInsightF[i].DRAW.toString().replace(",", ".")
        );
      }
    }

    const data = {
      DATA: props.financialInsightF,
      INV: props.invoiceToEvidate,
    };

    var c = props.invoiceToEvidate.reduce(
      (n, { INVOICE_AMOUNT }) => n + INVOICE_AMOUNT,
      0
    );

    var a = replaceComma(
      props.financialInsightF
        .filter((o) => o.DRAW != null)
        .reduce((n, { DRAW }) => replaceComma(n) + replaceComma(DRAW), 0)
    );

    if (a - c < -0.1 || a - c > 0.1) {
      //kvoli zaokruhlovaniu staci ked to bude medzi
      setValidace("notzero");
      return;
    }

    setIsRunning(true);
    const url_query_string =
      "invoiceevidence?kampan_id=" +
      props.vybranaZakazka +
      "&mediatyp=" +
      props.vybranyMediatyp +
      "&typ=evidence&mass=true";

    axios
      .post(url_query_string, data)
      .then((res) => {
        setIsRunning(false);
        props.setRecievedInvoices(res.data);
        props.setRecievedInvoicesF(res.data);
        props.setModalInvoiceToEvidateMass(false);
        props.setModalToEvidate(false);
        props.setIsError(false);
        props.setModalAction(false);
        props.setInfoBox("Faktura byla úspěšne zaevidována.");
      })
      .catch((err) => {
        setIsRunning(false);
        props.setIsError(true);
        props.setInfoBox("Fakturu se nezdařilo zaevidovat!");
        console.log(err);
      });
  };

  const toggleModal = () => {
    props.setModalInvoiceToEvidateMass(false);
    props.setModalAction(false);
    props.setModalToEvidate(false);
  };

  return (
    <>
      {props.modalInvoiceToEvidateMass && (
        <div class={"modal-window modal-window--w1200"}>
          <ModalRunning
            isRunning={isRunning}
            setIsRunning={setIsRunning}
            message={"Evidují fakturu."}
          />
          <div class="modal-window__wrap">
            <div class="modal-window__close">
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                Zbýva k čerpání:{" "}
                {numberWithSpaces(
                  props.invoiceToEvidate.reduce(
                    (n, { INVOICE_AMOUNT }) => n + INVOICE_AMOUNT,
                    0
                  ) -
                    replaceComma(
                      props.financialInsightF
                        .filter((o) => o.DRAW != null)
                        .reduce(
                          (n, { DRAW }) => replaceComma(n) + replaceComma(DRAW),
                          0
                        )
                    )
                )}
                <Validation
                  validace={validace}
                  validace_custom={"notzero"}
                  message={"Rozdíl musí být rovný nule!"}
                />
              </div>
              <div class="modal-window__content">
                <div class="table-container"></div>
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class={"table__col table__col--w200"}>Dodavatel</div>
                      <div class="table__col table__col--w120">
                        Verze mediaplánu
                      </div>
                      <div class="table__col table__col--w140">
                        Fakturované období
                      </div>
                      <div class="table__col table__col--w140 table__col--agentura-header">
                        Agentura NetNet FP
                      </div>
                      <div class="table__col table__col--w140 table__col--agentura-header">
                        Agentura NetNet MP
                      </div>
                      <div class="table__col table__col--w140 table__col--klient-header">
                        Klient NetNet FV
                      </div>
                      <div class="table__col table__col--w140">Čerpání</div>
                      <div class={"table__col table__col--flexible"}>
                        Delta (prodej-nákup)
                      </div>
                    </div>
                  </div>
                  <div class="table__body">
                    {props.financialInsightF.map((financialInsight, index) => (
                      <div class={"table__row"} key={financialInsight.ID}>
                        <div class="table__col table__col--w200">
                          {financialInsight.VYDAVATEL}
                        </div>

                        <div
                          class="table__col table__col--w120"

                          /* style={{
                            color: "blue",
                            "text-decoration": "underline",
                          }}*/
                        >
                          {financialInsight.TV_SPONZORING != null
                            ? financialInsight.ID_MEDIAPLAN_VERSION_FE + ".0s"
                            : financialInsight.ID_MEDIAPLAN_VERSION_FE + ".0"}
                        </div>

                        <div class="table__col table__col--w140">
                          {financialInsight.DATUM_UVEREJNENI}
                        </div>

                        <div class="table__col table__col--w140 table__col--agentura">
                          {numberWithSpaces(
                            parseFloat(
                              financialInsight.MNNN == null
                                ? 0
                                : financialInsight.MNNN
                            ) +
                              parseFloat(
                                financialInsight.DRAW == null
                                  ? 0
                                  : parseFloat(
                                      financialInsight.DRAW.toString().replace(
                                        ",",
                                        "."
                                      )
                                    )
                              )
                          )}
                        </div>
                        <div class="table__col table__col--w140 table__col--agentura">
                          {numberWithSpaces(financialInsight.MNNN_MP_D)}
                        </div>

                        <div class="table__col table__col--w140 table__col--klient">
                          {numberWithSpaces(financialInsight.MNNP)}
                        </div>

                        <div class="table__col table__col--w140">
                          <input
                            class={"form-input"}
                            onKeyDown={(e) => checkValidInput(e)}
                            onPaste={(e) => CheckValidPaste(e)}
                            value={replaceDot(financialInsight.DRAW)}
                            onChange={(e) =>
                              Drawing(financialInsight.ID, e.target.value)
                            }
                          />
                        </div>

                        <div class={"table__col table__col--flexible"}>
                          {numberWithSpaces(
                            replaceComma(financialInsight.MNNP) -
                              (financialInsight.MNNN == null
                                ? 0
                                : financialInsight.MNNN) -
                              (financialInsight.DRAW == null
                                ? 0
                                : parseFloat(
                                    financialInsight.DRAW.toString().replace(
                                      ",",
                                      "."
                                    )
                                  ))
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div class="modal-window__actions">
                <a
                  className="btn btn-medium btn--primary"
                  onClick={EvidateInvoice}
                >
                  Uložit
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
