import axios from "axios";
import React, { useEffect, useState } from "react";
import ModalAssignClient from "./UsersManagement-Modal_assign_client";
import ModalRunning from "./modal_running";
import Loader from "./Loader";
import Filter from "./Filter";
import ModalInfoList from "./modal_info_list";

export default function UsersManagement(props) {
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [usersToUpdate, setUsersToUpdate] = useState([]);
  const [usersClients, setUsersClients] = useState([]);
  const [orders, setOrders] = useState([]);

  const [isRunning, setIsRunning] = useState(false);
  const [sortDirection, setSortDirection] = useState(false);

  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);
  const [usersF, setUsersF] = useState([]);

  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const [isError, setIsError] = useState(false);
  const [infoBox, setInfoBox] = useState("");

  useEffect(() => {
    axios.get("/users").then(
      (res) => {
        console.log(res.data);

        setUsers(res.data);
        setUsersF(res.data);
        // setLoading(false);
      } //,
      //(err) => {
      //  console.log(err);
      //}
    );
    axios.get("/usersclients").then(
      (res) => {
        setUsersClients(res.data);
        console.log(res.data);
        setLoading(false);
      },
      (err) => {
        console.log(err);
        setLoading(false);
      }
    );
  }, []);

  const handleChangeInput = (event, index, ID, col) => {
    const users_a = [...users];
    const usersToUpdate_a = [...usersToUpdate];

    var value_to_change;
    value_to_change =
      col == "DOBROPIS" ? (event.target.checked ? 1 : 0) : event.target.value;

    users_a[index][col] = value_to_change;

    //treba otestovat ci uzivatel nemeni rovnakeho uzivatela, ak ano tak len updatnejme v novom array hodnotu
    if (usersToUpdate_a.find((o) => o.ID === ID) === undefined) {
      usersToUpdate_a.push(users_a[index]);
    } else {
      const obj = usersToUpdate_a.find((o) => o.ID === ID);
      obj[col] = value_to_change;
    }

    setUsers(users_a);
    setUsersToUpdate(usersToUpdate_a);
  };

  const UpdateUsersRole = () => {
    const data = usersToUpdate;
    setIsRunning(true);
    axios.post("/users?type=roles", data).then(
      (res) => {
        setOrders(res.data);
        setIsError(false);
        res.data.length > 0
          ? setInfoBox(
              "Role byla úspěšne aktualizována. Zároveň byl uživatel odstráněn z nasledujícich zakázek:"
            )
          : setInfoBox(
              "Role byla úspěšne aktualizována. Všichni zakázky zustali beze změny."
            );

        console.log(res);
        setIsRunning(false);
      },
      (err) => {
        console.log(err);
        setIsRunning(false);
        setIsError(true);
        setInfoBox("Roli se nezdařilo aktualizovat.");
      }
    );
  };

  const Deaktivovat = (ID) => {
    const data = users.find((user) => user.ID == ID);

    setIsRunning(true);
    axios.post("/users?type=deactivation", data).then(
      (res) => {
        setUsers(res.data);
        setUsersF(res.data);

        setIsError(false);
        console.log(res);
        setIsRunning(false);
      },
      (err) => {
        console.log(err);
        setIsRunning(false);
        setIsError(true);
        setInfoBox("Uživatelé se nezdařilo deaktivovat.");
      }
    );
  };

  const sortColumn = (colName) => {
    const values = [...usersF];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setUsersF(values);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <main class="content">
        <ModalRunning
          isRunning={isRunning}
          setIsRunning={setIsRunning}
          message="Ukladám změny."
        />
        <ModalInfoList
          isError={isError}
          setIsError={setIsError}
          infoBox={infoBox}
          setInfoBox={setInfoBox}
          list={orders}
          colName={"ID"}
        />
        <div class="container">
          <div class="content__in">
            <div class="content__main">
              <div class="table-container">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--pin">
                        <div class="table-pin"></div>
                      </div>

                      <div class="table__col table__col--w300">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("FULL_NAME")}
                        >
                          Uživatel
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={users}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setUsersF}
                          colName={"FULL_NAME"}
                        />
                      </div>

                      <div class="table__col table__col--w280">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("ROLE_NAME")}
                        >
                          Role
                        </span>
                        <Filter
                          filter={filter}
                          setFilter={setFilter}
                          filterVal={filterVal}
                          setFilterVal={setFilterVal}
                          table={users}
                          filterInput={filterInput}
                          setFilterInput={setFilterInput}
                          setFilteredTable={setUsersF}
                          colName={"ROLE_NAME"}
                        />
                      </div>
                      <div class="table__col table__col--w160">Dobropisy</div>

                      <div class="table__col table__col--w200"></div>
                      <div class="table__col table__col--w200"></div>
                    </div>
                  </div>

                  <div class="table__body">
                    {usersF.map((users, index) => (
                      <div class="table__row" key={index}>
                        <div class="table__col table__col--pin">
                          <div class="table-pin"></div>
                        </div>

                        <div class="table__col table__col--w300">
                          <div class="table__col--top">{users.FULL_NAME}</div>
                        </div>

                        <div class="table__col table__col--w280">
                          <select
                            className="form-select"
                            name="CLIENT_SUCCESS_CONSULTANT"
                            value={
                              users.ROLE === null ? "Vyberte rolu" : users.ROLE
                            }
                            onChange={(event) =>
                              handleChangeInput(event, index, users.ID, "ROLE")
                            }
                          >
                            <option value="0">Vyberte rolu</option>
                            <option value="1">Administrator</option>
                            <option value="2">Client Lead</option>
                            <option value="3">Client Success Consultant</option>
                            <option value="4">Nákupčí</option>
                            <option value="6">Nahlížeč</option>
                            <option value="7">Finančník</option>
                          </select>
                        </div>
                        <div class="table__col table__col--w160">
                          <div class="form-checkbox form-checkbox--no-label">
                            <input
                              type="checkbox"
                              defaultChecked={users.DOBROPIS}
                              onChange={(event) => {
                                handleChangeInput(
                                  event,
                                  index,
                                  users.ID,
                                  "DOBROPIS"
                                );
                              }}
                              id={users.ID}
                            />
                            <label htmlFor={users.ID}></label>
                          </div>
                        </div>

                        <div class="table__col table__col--w200">
                          {users.ACTIVE == 1 ? (
                            <ModalAssignClient
                              usersClients={usersClients}
                              setUsersClients={setUsersClients}
                              selectedUser={users.ID}
                              selectedUserName={users.EMAIL}
                              setOrders={setOrders}
                              setInfoBox={setInfoBox}
                              setIsError={setIsError}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div class="table__col table__col--w200">
                          {users.ACTIVE == 1 ? (
                            <a
                              class="btn btn--medium btn--red"
                              onClick={() => {
                                Deaktivovat(users.ID);
                              }}
                            >
                              Deaktivovat
                            </a>
                          ) : (
                            <a
                              class="btn btn--medium btn--primary"
                              onClick={() => {
                                Deaktivovat(users.ID);
                              }}
                            >
                              Aktivovat
                            </a>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div class="action-bar">
                  <div class="action-bar__in">
                    <a
                      onClick={() => {
                        UpdateUsersRole();
                      }}
                      className="btn btn--medium btn--primary"
                    >
                      Uložit práva
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
