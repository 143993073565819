import React, { useState } from "react";
import axios from "axios";
import { ConsoleLogger } from "@aws-amplify/core";
import ModalRunning from "./modal_running";

export default function ModalAssignClient(props) {
  const [modal, setModal] = useState(false);
  const [selectedUsersClients, setSelectedUsersClients] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const [isRunning, setIsRunning] = useState(false);

  const [sortDirection, setSortDirection] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  const ConfirmClients = () => {
    setIsRunning(true);

    const data = selectedUsersClients;

    axios.post("/usersclients?updating=user", data).then(
      (res) => {
        props.setUsersClients(res.data[0]["UC"]);
        props.setOrders(res.data[0]["ORDERS"]);
        props.setIsError(false);
        res.data[0]["ORDERS"].length > 0
          ? props.setInfoBox(
              "Klient byl úspěšne aktualizován. Zároveň byli uživatelé odstránění z nasledujícich zakázek:"
            )
          : props.setInfoBox(
              "Klient byl úspěšne aktualizován. Všichni zakázky zustali beze změny."
            );
        setModal(!modal);
        setIsRunning(false);
      },
      (err) => {
        console.log(err);
        setIsRunning(false);
      }
    );
  };

  const SelectClient = () => {
    setSelectedUsersClients(
      props.usersClients.filter((o) => o.USERSID === props.selectedUser)
    );
  };

  const SelectAll = () => {
    const values = [...selectedUsersClients];

    if (selectAll === true) {
      for (let i = 0; i < values.length; i++) {
        values[i]["USERS_ID"] = values[i]["USERSID"];
        values[i]["CLIENT_ID"] = values[i]["CLIENTSID"];
      }
    } else {
      for (let index = 0; index < values.length; index++) {
        values[index]["USERS_ID"] = null;
        values[index]["CLIENT_ID"] = null;
      }
    }

    setSelectedUsersClients(values);
    console.log(selectedUsersClients);

    var checkboxes = document.getElementsByName("foo");

    for (var i = 0, n = checkboxes.length; i < n; i++) {
      checkboxes[i].checked = selectAll;
      setSelectAll(!selectAll);
    }
  };

  const handleChangeInput = (event, index, user, client) => {
    debugger;
    const values = [...selectedUsersClients];

    if (event.target.checked === true) {
      values[index]["USERS_ID"] = user;
      values[index]["CLIENT_ID"] = client;
    } else {
      values[index]["USERS_ID"] = null;
      values[index]["CLIENT_ID"] = null;
    }

    setSelectedUsersClients(values);
    console.log(selectedUsersClients);
  };

  const sortColumn = (colName) => {
    const values = [...selectedUsersClients];
    // values.sort((a, b) => a.MEDIATYP.localeCompare(b.MEDIATYP));
    let collator = new Intl.Collator();
    values.sort((a, b) => {
      return sortDirection
        ? collator.compare(a[colName], b[colName])
        : collator.compare(b[colName], a[colName]);
    });
    setSortDirection(!sortDirection);
    setSelectedUsersClients(values);
  };

  return (
    <>
      <br></br>
      <a
        class="btn btn--medium btn--bordered"
        onClick={() => {
          toggleModal();
          SelectClient();
        }}
      >
        Přiřaďte klienta
      </a>
      {modal && (
        /*  <div className="modal">
          <div onClick={toggleModal} className="overlay"></div>*/
        <div class="modal-window">
          <div class="modal-window__wrap">
            <div class="modal-window__close" onClick={toggleModal}>
              <div class="icon">
                <svg>
                  <use
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref="/assets/img/sprite.svg#cross"
                  />
                </svg>
              </div>
            </div>
            <div class="modal-window__in">
              <div class="modal-window__title">
                Přiřaďte klienta uživateli {props.selectedUserName}
              </div>
              <div class="modal-window__content">
                <div class="table">
                  <div class="table__head">
                    <div class="table__row">
                      <div class="table__col table__col--w100"></div>
                      <div class="table__col table__col--w300">
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => sortColumn("CLIENT")}
                        >
                          Klient
                        </span>
                      </div>

                      <div class="table__col table__col--w200">Brand</div>
                    </div>
                  </div>

                  <div class="table__body">
                    {selectedUsersClients.map((usersClients, index) => (
                      <div
                        class="table__row"
                        key={usersClients.CLIENT + usersClients.BRAND}
                      >
                        <div class="table__col table__col--w100">
                          <div class="form-checkbox form-checkbox--no-label">
                            <input
                              type="checkbox"
                              defaultChecked={
                                usersClients.USERS_ID === null ? false : true
                                //usersClients.USERS_ID
                              }
                              onChange={(event) =>
                                handleChangeInput(
                                  event,
                                  index,
                                  usersClients.USERSID,
                                  usersClients.CLIENTSID
                                )
                              }
                              name="foo"
                              id={usersClients.CLIENT + usersClients.BRAND}
                            />
                            <label
                              htmlFor={usersClients.CLIENT + usersClients.BRAND}
                            ></label>
                          </div>
                        </div>
                        <div class="table__col table__col--w300">
                          {usersClients.CLIENT}
                        </div>
                        <div class="table__col table__col--w200">
                          {usersClients.BRAND}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div class="modal-window__actions">
                <ModalRunning
                  isRunning={isRunning}
                  setIsRunning={setIsRunning}
                  message="Měnim klienty."
                />
                <a
                  class="btn btn--medium btn--primary"
                  onClick={() => {
                    ConfirmClients();
                  }}
                >
                  Potvrdit změny
                </a>
                <a class="btn btn--medium btn--bordered" onClick={SelectAll}>
                  Vybrat vše
                </a>
                <a class="btn btn--medium btn--bordered" onClick={toggleModal}>
                  Storno
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
