import React, { useEffect, useState, useRef } from "react";
import { cutFile, myfunction } from "../helpers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Filter from "./Filter";
import {
  CheckValidPaste,
  checkValidInput,
  replaceDot,
  numberWithSpaces,
} from "../helpers";
export default function FinancialInsightStandardTable(props) {
  const [filter, setFilter] = useState([{ INIT: false }]);
  const [filterInput, setFilterInput] = useState([]);

  const [filterVal, setFilterVal] = useState([{ INIT: "" }]);

  const headerRef = useRef(null);
  const [leftOffset, setLeftOffset] = useState(0);

  useEffect(() => {
    myfunction(headerRef, leftOffset, setLeftOffset, 550, window);
  }, [leftOffset]);

  return (
    <div class="table">
      <div
        class={
          props.parentFI === "standard"
            ? "table__head table__head-fixed-order"
            : "table__head"
        }
        ref={headerRef}
      >
        <div class="table__row table__row--bold">
          <div
            class={
              props.parentFI === "standard"
                ? props.uctarna == 0
                  ? "table__col table__col--w200"
                  : "table__col table__col--w200 table__col--with-checkbox"
                : "table__col table__col--w200 table__col--with-checkbox"
            }
          >
            <span
              style={{ cursor: "pointer" }}
              onClick={() => props.sortColumn("VYDAVATEL")}
            >
              Dodavatel
            </span>
            <Filter
              filter={filter}
              setFilter={setFilter}
              filterVal={filterVal}
              setFilterVal={setFilterVal}
              table={props.financialInsight}
              filterInput={filterInput}
              setFilterInput={setFilterInput}
              setFilteredTable={props.setFinancialInsightF}
              colName={"VYDAVATEL"}
            />
          </div>
          <div class="table__col table__col--w200">Titul</div>
          <div class="table__col table__col--w120">
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("ID_MEDIAPLAN_VERSION_FE")}
              >
                <span>Verze mediaplánu</span>
              </OverlayTrigger>
            ) : (
              "Verze mediaplánu"
            )}
          </div>

          <div class="table__col table__col--w240">Název mediaplánu</div>
          <div class="table__col table__col--w120">Číslo faktury</div>

          <div class="table__col table__col--w140">
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("DATUM_UVEREJNENI")}
              >
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => props.sortColumn("DATUM_UVEREJNENI")}
                >
                  Fakturované období
                </span>
              </OverlayTrigger>
            ) : (
              "Fakturované období"
            )}

            <Filter
              filter={filter}
              setFilter={setFilter}
              filterVal={filterVal}
              setFilterVal={setFilterVal}
              table={props.financialInsight}
              filterInput={filterInput}
              setFilterInput={setFilterInput}
              setFilteredTable={props.setFinancialInsightF}
              colName={"DATUM_UVEREJNENI"}
            />
          </div>

          <div class="table__col table__col--w140 table__col--agentura-header">
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("MNN")}
              >
                <span>Agentura NetNet FP</span>
              </OverlayTrigger>
            ) : (
              "Agentura NetNet FP"
            )}
          </div>
          <div class="table__col table__col--w140 table__col--agentura-header">
            Agentura NetNet MP
          </div>

          <div class="table__col table__col--w140 table__col--klient-header">
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("MNPMP")}
              >
                <span>Klient NetNet MP</span>
              </OverlayTrigger>
            ) : (
              "Klient NetNet FP"
            )}
          </div>
          <div class="table__col table__col--w100 table__col--klient-header">
            Fee MP
          </div>
          <div class="table__col table__col--w140 table__col--klient-header">
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("MNP")}
              >
                <span>Klient NetNet FV</span>
              </OverlayTrigger>
            ) : (
              "Klient NetNet FV"
            )}
          </div>
          {props.parentFI === "dobropis" ? (
            <div class="table__col table__col--w140 table__col--klient-header">
              Klient NetNet FV - dobropis
            </div>
          ) : (
            <></>
          )}
          <div class="table__col table__col--w100 table__col--klient-header">
            Fee FV
          </div>
          {props.parentFI === "dobropis" ? (
            <div class="table__col table__col--w140 table__col--klient-header">
              Fee - dobropis
            </div>
          ) : (
            <></>
          )}
          <div class="table__col table__col--w140 table__col--klient-header">
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("CNNN")}
              >
                <span>Cost to Client FV</span>
              </OverlayTrigger>
            ) : (
              "Cost to Client"
            )}
          </div>
          {props.parentFI === "dobropis" ? (
            <div class="table__col table__col--w140">
              Cost to Client - dobropis
            </div>
          ) : (
            <></>
          )}
          <div
            class={
              props.parentFI === "standard"
                ? "table__col table__col--w140"
                : "table__col table__col--flexible"
            }
          >
            {props.parentFI === "standard" ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={props.renderTooltip("MNP_MNN")}
              >
                <span>Delta (prodej-nákup)</span>
              </OverlayTrigger>
            ) : (
              "Delta (prodej-nákup)"
            )}
          </div>
          {props.parentFI === "standard" ? (
            <>
              <div class="table__col table__col--w160">
                {props.parentFI === "standard" ? (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props.renderTooltip("MNP_MNN_FI_ID_LABEL")}
                  >
                    <span>Vysvětlení delty</span>
                  </OverlayTrigger>
                ) : (
                  "Vysvětlení delty"
                )}
              </div>

              <div class="table__col table__col--flexible">
                {props.parentFI === "standard" ? (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={props.renderTooltip("NOTE_1")}
                  >
                    <span>Komentář</span>
                  </OverlayTrigger>
                ) : (
                  "Komentář"
                )}
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div
        class={
          props.parentFI === "standard"
            ? "table__body table__body-fixed-order"
            : "table__body"
        }
      >
        {props.financialInsightF
          .filter(
            //dobropis moze byt naviazany na fakturu alebo nie, uctarna == 2 je bez naviazanej faktury
            //v pripade doborpis naviazaneho fakturu filtrejume podla ID faktury, ak nie tak podla ID vybratych riadkov FI
            props.parentFI === "dobropis"
              ? props.uctarna === 2
                ? (o) =>
                    props.invoiceToEvidate
                      .filter((item) => item.SELECTED == 1)
                      .map((item) => item.ID)
                      .includes(o.ID)
                : (o) => o.ID_INVOICE_TO_HELIOS == props.invoiceToEvidate
              : (o) => o.ID_INVOICE_TO_HELIOS == o.ID_INVOICE_TO_HELIOS
          )
          .map((financialInsight, index) => (
            <div
              class={
                financialInsight.ID_INVOICE_TO_HELIOS != null &&
                props.parentFI !== "dobropis"
                  ? "table__row table__row--highlight"
                  : "table__row"
              }
              key={financialInsight.ID}
              {...((props.parentFI === "standard") & (props.uctarna === 0)
                ? {
                    onClick: () => props.updateInvoice(financialInsight.ID),
                    style: { cursor: "pointer" },
                  }
                : {})}
            >
              {(props.parentFI !== "fakturace") &
              (props.parentFI !== "invoices") ? (
                props.uctarna == 0 ? (
                  <div class="table__col table__col--w200">
                    {financialInsight.VYDAVATEL}
                  </div>
                ) : (
                  <div class="table__col table__col--inner-cols table__col--w200">
                    <div class="table__inner-columns">
                      <div class="table__inner-col">
                        <div class="form-checkbox form-checkbox--no-label">
                          {(financialInsight.ID_INVOICE_TO_HELIOS == null) |
                          (props.uctarna === 2) ? (
                            <>
                              <input
                                type="checkbox"
                                defaultChecked={financialInsight.SELECTED}
                                onChange={(event) => {
                                  props.SelectMediaplan(event, index);
                                }}
                                id={financialInsight.ID}
                              />
                              <label htmlFor={financialInsight.ID}></label>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <div class="table__inner-col">
                        {financialInsight.VYDAVATEL}
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div class="table__col table__col--inner-cols table__col--w200">
                  <div class="table__inner-columns">
                    <div class="table__inner-col">
                      <div class="form-checkbox form-checkbox--no-label">
                        {props.parentFI == "invoices" ? (
                          props.uctarna == 1 ? ( //ked priradujeme fakturu k viacerym riadkom FP, tak mozme vybrat len jednu fakturu
                            props.vybranyClient ? ( //vybranyclient funguje len u recieved invoices FI, lebo je to obmedzene na jeden riadok
                              financialInsight.SELECTED === 1 ? ( // toto tu je kvoli tomu zeby som vedel odselektnut selektnuty
                                <>
                                  <input
                                    type="checkbox"
                                    // defaultChecked={mediaplanik.SELECTED}
                                    onChange={(event) => {
                                      props.SelectMediaplan(event, index);
                                    }}
                                    id={index}
                                  />
                                  <label for={index}> </label>
                                </>
                              ) : (
                                <></>
                              )
                            ) : (
                              <>
                                <input
                                  type="checkbox"
                                  // defaultChecked={mediaplanik.SELECTED}
                                  onChange={(event) => {
                                    props.SelectMediaplan(event, index);
                                  }}
                                  id={index}
                                />
                                <label for={index}> </label>
                              </>
                            )
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                // defaultChecked={mediaplanik.SELECTED}
                                onChange={(event) => {
                                  props.SelectMediaplan(event, index);
                                }}
                                id={index}
                              />
                              <label for={index}> </label>
                            </>
                          )
                        ) : financialInsight.ID_INVOICE_TO_HELIOS == null ? ( //from fakturace
                          <>
                            <input
                              type="checkbox"
                              // defaultChecked={mediaplanik.SELECTED}
                              onChange={(event) => {
                                props.SelectMediaplan(event, index);
                              }}
                              id={index}
                            />
                            <label for={index}> </label>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div class="table__inner-col">
                      {financialInsight.VYDAVATEL}
                    </div>
                  </div>
                </div>
              )}
              <div class="table__col table__col--w200">
                {financialInsight.TITUL}
              </div>
              <div
                class="table__col table__col--w120"

                /* style={{
                            color: "blue",
                            "text-decoration": "underline",
                          }}*/
              >
                {financialInsight.TV_SPONZORING != null
                  ? financialInsight.ID_MEDIAPLAN_VERSION_FE + ".0s"
                  : financialInsight.ID_MEDIAPLAN_VERSION_FE + ".0"}
              </div>
              <div class="table__col table__col--w240 table__col--smallfont">
                {cutFile(financialInsight.S3_KEY)}
              </div>
              <div class="table__col table__col--w120">
                {financialInsight.INVOICE_ALIAS_PORCIS}
              </div>
              <div class="table__col table__col--w140">
                {financialInsight.DATUM_UVEREJNENI}
              </div>

              <div class="table__col table__col--w140 table__col--agentura">
                {numberWithSpaces(financialInsight.MNNN)}
              </div>
              <div class="table__col table__col--w140 table__col--agentura">
                {numberWithSpaces(financialInsight.MNNN_MP_D)}
              </div>
              <div class="table__col table__col--w140 table__col--klient">
                {numberWithSpaces(financialInsight.MNNPMP)}
              </div>
              <div class="table__col table__col--w100 table__col--klient">
                {numberWithSpaces(financialInsight.FEE_MP)}
              </div>
              <div class="table__col table__col--w140 table__col--klient">
                {numberWithSpaces(financialInsight.MNNP)}
              </div>
              {props.parentFI === "dobropis" ? (
                <div class="table__col table__col--w140 table__col--klient">
                  <input
                    class={
                      props.validaceID == financialInsight.ID
                        ? "form-input form-input--invalid"
                        : "form-input"
                    }
                    name="OVD_GRPS_PERCENT"
                    onKeyDown={(e) => checkValidInput(e)}
                    onPaste={(e) => CheckValidPaste(e)}
                    value={replaceDot(financialInsight.MNNP_OPRAVA)}
                    onChange={(e) =>
                      props.Dobropising(
                        "MNNP_OPRAVA",
                        financialInsight.ID,
                        e.target.value
                      )
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              <div class="table__col table__col--w100 table__col--klient">
                {numberWithSpaces(financialInsight.FEE)}
              </div>
              {props.parentFI === "dobropis" ? (
                <div class="table__col table__col--w140">
                  <input
                    class={
                      props.validaceID == financialInsight.ID
                        ? "form-input form-input--invalid"
                        : "form-input"
                    }
                    name="OVD_GRPS_PERCENT"
                    onKeyDown={(e) => checkValidInput(e)}
                    onPaste={(e) => CheckValidPaste(e)}
                    value={replaceDot(financialInsight.FEE_OPRAVA)}
                    onChange={(e) =>
                      props.Dobropising(
                        "FEE_OPRAVA",
                        financialInsight.ID,
                        e.target.value
                      )
                    }
                  />
                </div>
              ) : (
                <></>
              )}
              <div class="table__col table__col--w140 table__col--klient">
                {numberWithSpaces(financialInsight.CNNN)}
              </div>
              {props.parentFI === "dobropis" ? (
                <div class="table__col table__col--w140">
                  {numberWithSpaces(
                    financialInsight.CNNN_OPRAVA == undefined
                      ? null
                      : financialInsight.CNNN_OPRAVA
                  )}
                </div>
              ) : (
                <></>
              )}
              <div
                class={
                  props.parentFI === "standard"
                    ? "table__col table__col--w140"
                    : "table__col table__col--flexible"
                }
              >
                {numberWithSpaces(financialInsight.MNNP_MNNN)}
              </div>
              {props.parentFI === "standard" ? (
                <>
                  <div class="table__col table__col--w160">
                    {financialInsight.MNNP_MNNN_FI_ID_LABEL}
                  </div>
                  <div class="table__col table__col--flexible">
                    {financialInsight.NOTE_1}
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        {props.parentFI === "standard" ? (
          <div class="table__row" key="sumacny">
            <div class="table__col table__col--w200 table__col--hidden"></div>
            <div class="table__col table__col--w200 table__col--hidden"></div>

            <div class="table__col table__col--w120 table__col--hidden"></div>
            <div class="table__col table__col--w240 table__col--hidden"> </div>

            <div class="table__col table__col--w120 table__col--hidden"></div>
            <div class="table__col table__col--w140 table__col--hidden"></div>

            <div class="table__col table__col--w140 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce((n, { MNNN }) => n + MNNN, 0)
              )}
            </div>
            <div class="table__col table__col--w140 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce(
                  (n, { MNNN_MP_D }) => n + MNNN_MP_D,
                  0
                )
              )}
            </div>
            <div class="table__col table__col--w140 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce((n, { MNNPMP }) => n + MNNPMP, 0)
              )}
            </div>
            <div class="table__col table__col--w100 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce((n, { FEE_MP }) => n + FEE_MP, 0)
              )}
            </div>
            <div class="table__col table__col--w140 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce((n, { MNNP }) => n + MNNP, 0)
              )}
            </div>
            <div class="table__col table__col--w100 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce((n, { FEE }) => n + FEE, 0)
              )}
            </div>
            <div class="table__col table__col--w140 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce((n, { CNNN }) => n + CNNN, 0)
              )}
            </div>
            <div class="table__col table__col--w140 table__col--highlight-green">
              {numberWithSpaces(
                props.financialInsightF.reduce(
                  (n, { MNNP_MNNN }) => n + MNNP_MNNN,
                  0
                )
              )}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
